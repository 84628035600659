<template>
  <ThePage :container-classes="'container-full'">
    <div class="top-content-container">
      <div class="top-content-wrapper container">
        <div class="top-content">
          <h1 class="main-title" v-text="pageData.home.title"></h1>
          <a
            class="pipeline-link"
            :href="localePath({ name: 'slug', params: { slug: $t('url.pipeline') }})"
          >
            <div>
              <p v-html="$t('home.sub_titles.in_progress', { count: labProductCounts.in_progress })"></p>
              <p v-html="$t('home.sub_titles.ordered', { count: labProductCounts.ordered })"></p>
            </div>
            <AngleRightIcon height="42px" width="14px" />
          </a>
        </div>
      </div>
      <img
        class="visible-xs"
        src="https://cdn.runrepeat.com/storage/uploads/home/header-image-720.jpg"
        srcset="https://cdn.runrepeat.com/storage/uploads/home/header-image-720.jpg 1x, https://cdn.runrepeat.com/storage/uploads/home/header-image-1080.jpg 1.5x, https://cdn.runrepeat.com/storage/uploads/home/header-image-1440.jpg 2x"
        :alt="$t('home.main_title')"
      >
      <img
        class="hidden-xs"
        src="https://cdn.runrepeat.com/storage/uploads/home/header-image-1920.jpg"
        srcset="https://cdn.runrepeat.com/storage/uploads/home/header-image-1920.jpg 1x, https://cdn.runrepeat.com/storage/uploads/home/header-image-2880.jpg 1.5x, https://cdn.runrepeat.com/storage/uploads/home/header-image-3840.jpg 2x"
        :alt="$t('home.main_title')"
      >
    </div>

    <section class="categories-links-container container">
      <CategoryLinkList :categories="pageData.categories_overview" />
    </section>

    <section class="guides-container container">
      <h2>{{ $t('pages.guides') }}</h2>
      <p v-html="$t('home.guides_text', { link: localePath({ name: 'news-topic', params: { topic: $t('url.guide') }}) })"></p>
      <TheSlider :slides="pageData.guides" />
    </section>

    <section class="reviews-container container">
      <div class="product-reviews">
        <h2>{{ $t('home.latest_reviews') }}</h2>
        <DropDownSelect
          v-model="selectedCategory"
          :options="latestReviewsCategories"
          :value-prop="'id'"
          select-phrase=""
          stop-event
          :theme-orange="false"
          style="width: 168px"
        />
      </div>
      <TheSlider :slides="selectedCategory.items" />
    </section>

    <section class="reviews-container container">
      <div class="product-reviews">
        <h2>{{ $t('home.popular_reviews') }}</h2>
        <DropDownSelect
          v-model="selectedCategory"
          :options="latestReviewsCategories"
          :value-prop="'id'"
          select-phrase=""
          stop-event
          :theme-orange="false"
          style="width: 168px"
        />
      </div>
      <TheSlider :slides="selectedCategory.popular" />
    </section>

    <section class="testing-container container">
      <h2>{{ $t('home.testing_process') }}</h2>
      <div class="testing-process">
        <div class="testing-slide" v-for="(slide, i) in pageData.testing_process">
          <div class="testing-slide-image">
            <TheImage v-if="slide.image" :image="slide.image" profile="thumb-md" />
          </div>
          <div class="testing-slide-text">
            <span v-html="(i+1)+'. '+slide.text"></span>
          </div>
        </div>
      </div>
    </section>

    <section class="featured-on">
      <div class="featured-on-images container">
        <img
          src="//cdn.runrepeat.com/img/featured_in/new_york_times.png"
          alt="newyorktimes_gs"
          width="202"
          height="28"
        />
        <img
          src="//cdn.runrepeat.com/img/featured_in/bbc.png"
          alt="bbc_gs"
          width="85"
          height="24"
        />
        <img
          id="wall_street"
          src="//cdn.runrepeat.com/img/featured_in/wall_street_journal.png"
          alt="wsj_gs"
          width="221"
          height="19"
          style="height: 19px"
        />
        <img
          src="//cdn.runrepeat.com/img/featured_in/guardian.png"
          alt="guardian_gs"
          width="162"
          height="29"
        />
        <img
          src="//cdn.runrepeat.com/img/featured_in/washington_post.png"
          alt="thewashingtonpost_gs"
          width="192"
          height="30"
        />
      </div>
    </section>
  </ThePage>
</template>

<script setup>
import CategoryLinkList from "~/components/home/CategoryLinkList";

import { useApiFetch } from "~/composables/useApiFetch";
import { useMainStore } from "~/stores/main";
import { usePageStore } from "~/stores/page";
import pageHead from "~/utils/pageHead";
import TheSlider from "~/components/core/TheSlider";
import DropDownSelect from "~/components/core/DropDownSelect";
import { nextTick, onMounted } from "vue";
import { loadMediavine } from "~/utils/ads";

const localePath = useLocalePath();
const main = useMainStore();
const page = usePageStore();

const { data } = await useApiFetch('api/pages/home');

page.fillHomePageData(data.value);

const pageData = ref(data.value.page_data);

const labProductCounts = computed(() => pageData.value.home.lab_products || {published: 300, in_progress: 20, ordered: 10})
const latestReviewsCategories = computed(() => pageData.value.latest_products)
const selectedCategory = ref(pageData.value.latest_products[0]);

onBeforeRouteLeave(() => {
  page.expectedType = 'ranking';
})

function buildScripts() {
  const scripts = [];

  scripts.push({
    body: true,
    hid: `website-schema-json-ld`,
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: 'https://runrepeat.com',
      name: 'RunRepeat',
      alternateName: 'RunRepeat',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://runrepeat.com/search?q={query}',
        'query-input': 'required name=query',
      },
    }),
    type: 'application/ld+json',
  });

  return scripts;
}

const route = useRoute();

const withMetaLinks = Object.keys(route.query).length === 0;

const head = pageHead.getHead('front-home', withMetaLinks);
head.script.push(...buildScripts())
useHead(head)

onMounted(() => {
  nextTick(() => {
    loadMediavine();
  })
});
</script>


<style lang="scss">
body.front-home {
  padding-top: 65px;
}

.front-home {
  h2 {
    margin-top: 0;
  }

  section {
    margin-top: 60px;
  }

  .top-content-container {
    position: relative;
    min-height: 376px;

    .top-content-wrapper {
      position: relative;
      padding-top: 45px;
    }

    .top-content {
      max-width: 380px;
    }

    h1.main-title {
      font-size: 60px;
      font-weight: bold;
      line-height: 1;
      color: $theme-color;
      margin-top: 0;
      margin-bottom: 0;
    }

    .pipeline-link {
      margin: 8px 0 0;
      padding: 15px;
      background-color: $theme-color-transparent;
      max-width: 270px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      box-shadow: #000 0 2px 1px 0;

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: white;
      }

      p {
        margin-bottom: 0;
      }

      svg {
        fill: white;
      }
    }
    img {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;

      @media (min-width: 1921px) {
        object-fit: contain;
      }
    }
    
    @media (max-width: $break_xs_under) {
      min-height: 260px;

      .top-content-wrapper {
        padding-top: 30px;
        padding-bottom: 15px;
      }
      .top-content {
        max-width: 320px;
      }
      h1.main-title {
        font-size: 50px;
      }
    }

    @media (min-width: $break_md_upper) {
      .top-content-wrapper {
        padding-top: 80px;
      }
      .top-content {
        max-width: 640px;
      }
    }
  }

  .guides-container {
    margin-top: 30px;
  }

  .testing-process {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

    .testing-slide {
      width: calc(33.333% - 20px);
    }

    .testing-slide-image {
      aspect-ratio: 3 / 2;

      img {
        width: 100%;
      }
    }
    .testing-slide-text {
      padding: 5px 0 20px;
    }

    @media (max-width: $break_sm_under) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 15px;

      .testing-slide {
        display: flex;
        flex-direction: row;
        gap: 15px;
        width: 100%;
      }
      .testing-slide-image {
        width: 40%;
      }
      .testing-slide-text {
        width: 60%;
        padding: 0;
        display: flex;
        align-items: center;
      }
    }
  }

  .product-reviews {
    display: flex;
    align-items: flex-end;
    justify-content: left;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 10px;
    margin-bottom: 10px;

    h2 {
      margin-bottom: 0;
    }
  }

  .featured-on {
    background-color: #eee;
  }

  .featured-on-images {
    text-align: justify;
    padding: 43px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: $break_md_under) {
      padding: 0 20px 20px;
      justify-content: space-around;
      img {
        padding: 20px 15px 0 0;

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    @media (max-width: $break_xs_under) {
      justify-content: center;
    }

    #wall_street {
      display: inline-block;
      margin-top: 5px;
      height: 19px;
      @media (max-width: $break_md_under) {
        padding-top: 0;
        margin-top: 24px;
      }
    }
  }
}
</style>
